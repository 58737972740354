.surgeries {
  background-color: #eee;
  padding: 2rem 2rem;
  @include mq($from: md-plus) {
    min-height: $dashboard-height;
    padding: 2rem 0;
    display: grid;
    grid-template-columns: 2rem 1fr 2rem;
    grid-row-gap: 2rem;
    grid-template-areas:
    ". upcoming ."
    ". previous .";
    grid-auto-rows: max-content;
  }
  @include mq($from: xl) {
    grid-template-columns: 7rem 1fr 7rem;
  }
  a{
    color: initial;
  }
  a:hover{
    text-decoration: none;
  }

}

.upcoming-surgeries {
  grid-area: upcoming;
  margin-bottom: 2rem;
}
.previous-surgeries{
  grid-area: previous;
}

.surgeries__header {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.surgeries__header__title {
}

.surgeries__header__action {
  margin-left: 1rem;
}

.surgeries__grid__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-gap: 1rem;
  @include mq($from:xl){
      grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
}

.surgeries__grid__item {
  height: 100%;
  & > a{
    height: 100%;
  }
}
