.login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc( 100vh - #{$navigation-height} );
    min-height: min-content;
    .section{
        padding-bottom: 1em;
        @include mq($from:lg-plus){
            padding-bottom: 5em;
        }
    }
}


.sign-up__meta{
    @include flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.sign-up__input{
    width: 100%;
}

.sign-up__input:first-of-type{
    margin-bottom: 1rem;
}