.message {
  display: none;
  padding: 1rem;
  background-color: rebeccapurple;
  color: #fff;
}

.message__container {
  padding: 3rem 4rem;
  max-width: 40rem;
}

.message__title {
  margin: 0;
}

.message__paragraph {
  line-height: 1.8;
}
