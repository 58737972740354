.ExploreRoot {
  height: 100%;
  background: #F1F6F8;
  margin: -30px -20px;
}

.ExploreNavbar {
  background: #fff;
  height: 50px;
  border-bottom: 1px solid #e7e7e7;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0 40px;

  a {
    color: #000;
    margin-right: 40px;
    display: flex;
    height: 100%;
    align-items: center;

    &.is-active {
      border-bottom: 5px solid #039de1;
    }
  }
}

.ExploreContent {
  padding: 20px 40px;

  h3 {
    color: #000;
    font-size: 24px;
    font-weight: 400;
  }
}