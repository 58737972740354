.UserProfilePage {
  margin: -30px -20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .UserProfilePage-Header {
    padding: 30px 20px 40px 20px;
    border-bottom: 1px solid #e7e7e7;
    background: #fff;

    .UserAvatar-Wrapper {
      position: relative;
      display: block;
      width: 172px;

      svg {
        background-color: #EE0000;
        width: 41px;
        height: 41px;
        padding: 11px;
        border-radius: 50%;
        color: #fff;
        position: absolute;
        bottom: 20px;
        right: 0;
      }
    }

    .UserAvatar {
      width: 172px !important;
      height: 172px !important;
    }

    .UserBio {
      section {
        max-width: 80%;
        padding-left: 20px;

        h4 {
          font-size: 22px !important;
          color: #000;
        }

        h5 {
          font-size: 14px;
          font-weight: 600;
          color: #000;
          margin-top: 15px;
        }

        p {
          font-size: 13px;
          color: #6d6d6d;
        }
      }

      .UserBio-Subscribers {
        display: flex;
        align-items: center;
        color: #6d6d6d;
        font-size: 14px;

        button {
          margin-right: 20px;
          box-shadow: none;
          padding: 5px 24px;
        }
      }
    }

    .UserAdminActions {
      button {
        font-size: 11px;
        margin-bottom: 10px;
        padding: 7px 5px;
        width: 100%;
      }
    }
  }

  .UserNavbar {
    height: 50px;
    background: #fff;
    border-bottom: 1px solid #e7e7e7;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 40px;

    a {
      color: #000;
      margin-right: 40px;
      display: flex;
      height: 100%;
      align-items: center;

      &.is-active {
        border-bottom: 5px solid #039de1;
      }
    }
  }

  .PublicMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 80px 0;

    h1 {
      font-size: 26px;
    }

    button {
      margin-top: 20px;
    }
  }

  .tabSidebarButtonLabel {
    justify-content: start;
    padding-left: 24px;

    svg {
      margin-right: 10px !important;
    }
  }
}
