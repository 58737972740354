.news{
    list-style-type: none;
    margin: 0;
    @extend .pane;

    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // grid-gap: 2rem;
    
}

.news-item{
    //position: relative;
    h2{
        // position: absolute;
        // bottom: 0;
        // margin: 0;
        // right: 0;
        // left: 0;
        line-height: 1;
    }
}

.news-item:first-of-type{
    grid-column: 1 / span 2;
}