/* Modal CSS */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  padding: 2rem;
  border-radius: 10px;
  max-height: 100vh;
  overflow-y: auto;
}


.modal.display-block {
  display: block;
}

.modal.display-none {
  display: none;
}