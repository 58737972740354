.site-footer{
    background-color: #111;
    min-height: min-content;
    color: #fff;
    svg{
        max-width: 12rem;
    }
    svg path{
        fill: #fff;

    }
}

.site-footer__container{
    padding: 2rem;
    @include mq($from:lg-plus){
        padding: 6rem 6rem ;
    }

}

.site-footer__grid{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    @include mq($from:md-plus){
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 2rem;
    }
}

.site-footer__column{
    h1{
        padding-top: .5rem;
    }
}

.site-footer__column-1{
    grid-column: span 2;
}
.site-footer__column-2{
    grid-column: span 6;
}
// .site-footer__column-3{
//     grid-column: span 2;
// }
// .site-footer__column-4{
//     grid-column: span 2;
// }
.site-footer__list{
    list-style-type: none;
    padding: 0;
    margin: 0;   
}
.site-footer__anchor{
    display: block;
    margin-bottom: 1rem;
} 