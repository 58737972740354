.surgeon{

}

.surgeon__item {
  display: flex;
  align-items: center;
  position: relative;
}

.surgeon__image {
  margin-right: 1rem;
  border-radius: 50%;
}

.suregon__name {
  font-size: .8em;
  margin-bottom: 0;
  @include mq($from:md){
      font-size: 1.2rem;
  }
}
