.sign-up{
    height: $dashboard-height;
    min-height: min-content;
    grid-auto-rows: initial;
    .section{
        align-self: center;
    }
    .header{
        max-width: 40em;
        margin: 0 auto;
        margin-bottom: 2rem;
    }
}

.sign-up__form{
    max-width: 40em;
    margin: 0 auto;
    @include mq($from:lg-plus){    
        padding-bottom: 5rem;
    }
    & > *{
        margin-bottom: 1rem;
    }
    input[type="text"], input[type="password"]{
        width: 100%;
    }
}



.sign-up__form__name{
    display: flex;
    & > *{
        flex: 1 1 auto;
        &:first-child{
            margin-right: 1rem;
        }
    }
}

.sign-up__form__agreement{
    display: flex;
    align-items: baseline;
    label{
        margin: 0; padding: 0;
        padding: 0 1rem;
    }
    input[type="checkbox"]{
        order: -1;
        
    }
}

.sign-up__submit{

}

.sign-up__label{
    text-transform: capitalize;
}