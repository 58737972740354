input{
    max-width: 100%;
    padding: .5em 1em;
    border: 1px solid #eee;
}
textarea{
    width: 100%;
    min-height: 24em;
    padding: 1em;
    border: 1px solid #eee;
}