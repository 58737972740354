@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700|Open+Sans&display=swap');

// colors
$white: #fff;
$black: #000;
$brand: #0ca3d9;
$grey: #555;
$light-grey: #888;


$link-color: $brand;
$body-bg: $white;
$body-color: $grey;


$navigation-height: 6.25rem;

/* ------ TYPOGRAPHY ------- */


$font-size-base: 1rem;
$font-family-base: "Open Sans", sans-serif, "Helvetica Neue", helvetica, arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: Courier;
$headings-font-family: "Montserrat", sans-serif;



// Links

$link-decoration: none;

$headings-margin-bottom: .5rem;
$paragraph-margin-bottom: 1rem;


// To enable support for browsers that do not support @media queries,
// (IE <= 8, Firefox <= 3, Opera <= 9) set $mq-responsive to false
// Create a separate stylesheet served exclusively to these browsers,
// meaning @media queries will be rasterized, relying on the cascade itself
$mq-responsive: true;

// Name your breakpoints in a way that creates a ubiquitous language
// across team members. It will improve communication between
// stakeholders, designers, developers, and testers.
$mq-breakpoints: (
    sm:  320px,
    sm-plus:  480px,
    md: 640px,
    md-plus: 720px,
    lg: 960px,
    lg-plus: 1200px,
    xl: 1600px
);

// Define the breakpoint from the $mq-breakpoints list that should
// be used as the target width when outputting a static stylesheet
// (i.e. when $mq-responsive is set to 'false').
$mq-static-breakpoint: desktop;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width, e.g. (mobile, tablet, desktop).
$mq-show-breakpoints: (sm,sm-plus,md,md-plus,lg,lg-plus,xl);


$dashboard-height: calc(100vh - #{$navigation-height});
