.surgery__content {
  padding: 1rem;
  @include mq($from:lg){
    padding: 0;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: grid;
    grid-template-columns:
      2rem 0rem minmax(25rem, 50rem) minmax(5rem, 1fr) minmax(15rem, 25rem)
      0rem 2rem;
    grid-template-areas: ". . main . sidebar . .";
  }
  @include mq($from:xl){
    padding-top: 7rem;
    padding-bottom: 7rem;
    grid-template-columns:
    15rem 0rem minmax(20rem, 50rem) minmax(10rem, 1fr) minmax(20rem, 25rem)
    0rem 7rem;
  }
}

.surgery__description {
  line-height: 1.6;
  color: #555;
  @include mq($from:lg){
    grid-area: main;
  }
}

.surgery__sidebar {
  color: #444;
  @include mq($from:lg){
    grid-area: sidebar;
  }
  .panel{
    margin-bottom: 0rem;
  }
}

