.surgery__hero{
    display: flex;
    flex-direction: column-reverse;
    background-color: #111;
    color: #fff;
    @include mq($from: md-plus){
        flex-direction: row;
        // padding: 2rem;
    }
    > div{
        @include mq($from: md-plus){
            width: 50%;
        }
        
    }
}

.surgery__title{
    margin-bottom: .5em;
}

.surgery__hero-info{
    padding: 1rem;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    @include mq($from: md-plus){
        padding-left: 2rem;
    }
}

.surgery__time{
    display: none;
}

.surgery__register{
    button + button{
        margin-left: 1rem;
    }
}

.single-surgery--previous{
    .surgery__hero{
        display: flex;
        padding: 0;
        iframe{
            width: 100%;
        }
    }
    .surgery__hero-info{
        @media (min-width: 720px){
            padding-left: 2rem;

        }
    }
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    width: 100% !important;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

// .surgery__hero {
//   background-color: #222;
//   color: #fff;
//   @include mq($from: md-plus) {
//     display: grid;
//     padding: 3rem 0;
//     grid-template-columns:
//       1rem 0rem minmax(8rem, 30rem) minmax(2rem, 1fr) minmax(10rem, 35rem)
//       0rem 1rem;
//     grid-template-areas: ". . a . b . .";
//   }
//   @include mq($from: lg) {
//     grid-template-columns:
//       5rem 0rem minmax(26rem, 34rem) minmax(2rem, 1fr) minmax(25rem, 2fr)
//       0rem 5rem;
//   }
//   @include mq($from: xl) {
//     grid-template-columns:
//       15rem 0rem minmax(26rem, 34rem) minmax(2rem, 1fr) minmax(25rem, 3fr)
//       0rem 7rem;
//   }
// }

// .surgery__hero-info {
//   padding: 2rem;
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   grid-template-rows: max-content max-content;
//   grid-template-areas:
//     "title title"
//     "surgeon register";
//   @include mq($from: md-plus) {
//     padding: 0;
//     align-self: center;
//     grid-area: a;
//   }
// }

// .surgery__title {
  
//   grid-area: title;
//   padding: 0;
//   margin: 0;
//   margin-bottom: 2rem;
//   font-size: 1.6em;
//   //margin-top: 3rem;
//   @include mq($from:md-plus){
//     font-size: 1.5em;
//     }
//     @include mq($from:lg){
//         font-size: 2em;
//         }
//         @include mq($from:xl){
//             font-size: 2.5em;
//             }
// }

// .surgery__image {
//   @include mq($from: md-plus) {
//     grid-area: b;
//   }
// }

// .surgery__img {
// }

// .surgery__hero-surgeon {
//   grid-area: surgeon;
//   display: flex;
//   align-content: center;
//   @include mq($from:lg){
//   }
// }

// .surgery__register {
//   align-self: end;
//   grid-area: register;
//   text-align: right;
// }

// .surgery__time {
//   display: block;
//   display: none;
//   margin-bottom: 2rem;
//   text-align: right;
//   font-weight: bold;
// }
