.wrapper {
    padding: 2rem 2rem;
    @include mq($from: lg) {
      display: grid;
      grid-template-columns: 2rem 1fr 2rem;
      grid-row-gap: 2rem;
      grid-template-areas:
      ". a .";
      grid-auto-rows: max-content;
    }
    @include mq($from: xl) {
      grid-template-columns: 4rem 1fr 4rem;
    }
}

.wrapper > *:first-child{
    grid-area: a;
}
