
.dashboard{
  background-color: #fff;
  .wrapper{
    @include mq($from:lg){
        grid-template-columns: 1rem 1fr 2rem;
    }
  }
}

.user-dashboard{
  background-color: #fff;
}

.dashboard--has-sidebar {
  @include mq($from: lg) {
    .dashboard__sidebar + .dashboard{
      height: calc(100vh - #{$navigation-height});
      min-height: min-content;
      overflow: scroll;
    }
    display: grid;
    grid-template-columns: 21rem 1fr;
    .dashboard .site-navigation {
      justify-content: flex-end;
      .brand {
        display: none;
      }
    }
  }

  .dashboard__sidebar {
    display:flex;
    .dashboard__sidebar__nav {
      list-style-type: none;
      padding: 2rem 2rem 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 0;
    }

    .dashboard__sidebar__nav li {
      margin-right: 1rem;
    }
    @include mq($from: lg) {
      display: flex;
      height: calc(100vh - #{$navigation-height});
      color: #333;
      align-self: stretch;
      position: sticky;
      top: 0px;
      .dashboard__sidebar__message{
          align-self: center;
          font-family: serif;
          padding: 0em 2em 0em;
          text-align: center;
          h1{
            font-weight: 100;
            font-size: 1em;
          }
      }
      .dashboard__sidebar__nav {
        align-self: flex-start;
        display: block;
        padding: 0;
        border: 1px solid #eee;
        border-radius: .5rem;
        margin: 2rem;
      }
      .dashboard__sidebar__nav li {
        text-align: left;
        margin-right: 0;
        &:last-of-type a{
          border-bottom: 0;
          &:hover{
            border-radius: 0 0 10px 10px;
          }
        }
        &:first-of-type a{
          &:hover{
            border-radius: 10px 10px 0 0;
          }
        }
        a {
          border-bottom: 1px solid #eee;
          color: #333;
          display: block;
          padding: 1rem;
          padding-left: 2rem;
          margin-top: 0rem;
          font-size: 1.2em;
          &.is-active{
            border-left: .3rem solid $brand;
          
          }
          &:hover{
            text-decoration: none;
            transition: .7s all;
            color: $brand;
            //border-left: .3rem solid $brand;
          }
        }
      }
    }
  }

  .dashboard__sidebar__navigation {
    background-color: rgba(255, 255, 255, 0.06);
    border-bottom: 0;
    .brand {
      color: #fff;
    }
  }
}


.admin-dashboard__grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem; 
}

.latest-posts{
  grid-column: span 2;
}

  .news-item{
    margin-bottom: 2rem;
  }

.latest-surgeries{
  grid-column: span 2;
  @include mq($from:lg-plus){
      grid-column: span 2;
  }
}

.latest-users{
  grid-column: span 2;
  @include mq($from:lg-plus){
      grid-column: span 2;
  }
}


.from-blog{
  display: none;
  grid-column: span 2;
  @include mq($from:lg-plus){
      grid-column: span 1;
  }
  .list-group-item h6{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 65%;
  }
}



/* ------------------ USER DASHBOARD ----------------- */


.user-dashboard__sidebar {
  
  .dashboard__sidebar__nav {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    padding: 0;
    border: 1px solid #eee;
    border-radius: 0 0 10px 10px;
    margin: 0 2rem 0 2rem;
    list-style-type: none;
    @include mq($from:lg){
      margin-left: 3rem;
    } 
  }
  .dashboard__sidebar__nav li{
    a {
      border-bottom: 1px solid #eee;
      color: #333;
      display: block;
      padding: 1rem;
      padding-left: 2rem;
      margin-top: 0rem;
      font-size: 1.2em;
      width: 100%;
      &:hover{
        text-decoration: none;
        background-color: $brand;
        color: #fff;
      } 
    }
    &:last-of-type a{
      border-bottom: none;
    }
  }
  @include mq($from: lg) {
    height: $dashboard-height;
    color: #333;
    align-self: stretch;

    
    .dashboard__sidebar__nav li {
      text-align: left;
      margin-right: 0;
      &:last-of-type a{
        border-bottom: 0;
        &:hover{
          border-radius: 0 0 10px 10px;
        }
      }
      &:first-of-type a{
        &:hover{
          border-radius: 0 0 0 0;
        }
      }
      
    }
  }
}

.sidebar__profile{
  border: 1px solid #eee;
  padding: 2rem;
  margin: 2rem 2rem 0 2rem;
  border-radius: 10px 10px 0 0;
  border-bottom: 0;
  @include mq($from:lg){
    margin-left: 3rem;
  }
  .profile__avatar{

  }
}


.registered-surgeries{
  .list-group{
    min-height: 217px; // WARNING 
  }
  .list-group-item {
    flex: 1 1 auto;
    &:only-child{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
