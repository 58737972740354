// Global Navigation
.site-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  z-index: 100;
  border-bottom: 1px solid #eee;

  @include mq($from: lg) {
    padding: 0;
  }
}


// Brand
.brand {
  text-transform: uppercase;
  color: $black;
  line-height: 1;
  display: block;

  &:hover{
    text-decoration: none;
  }
  svg{
    height: 2rem;
    @include mq($from:md-plus){
        height: 4rem;
    }
  }
}

.site-navigation__brand {
  padding: .5rem 2rem;
  img {
    max-height: 45px;
  }
  //border-right: 1px solid #eee;
}

// Hamburger Menu
.hamburger {
  @include reset-button;
  margin-right: 0rem;
  display: block;
}
.site-navigation__hamburger {
  padding: 1.344rem 1rem;
  @include mq($from: md-plus) {
    display: none;
  }
}

// Site Nav Menu

.nav {
  //border: 1px solid pink;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  display: flex;
  align-items: center;
  a{
    font-weight: bolder;
    &:hover{
      text-decoration: none;
    }
  }
}

.site-navigation__nav {
}

.nav-list {
  display: none;
  margin: 0;
  padding: 0;
  list-style-type: none;
  @include mq($from: md-plus) {
    display: flex;
  }
}
.site-navigation__nav__list {
}

.nav-item {
  line-height: 1;
}

.site-navigation__nav__item {
}

.nav-anchor {
  display: block;
  padding: 1.5rem 1rem;
  line-height: 1;
  @include mq($from: lg) {
    padding: 1.5rem 1.5rem;
  }
}

.site-navigation__user {
  display: none;
  align-items: center;
  padding-right: 2rem;
  @include mq($from: md-plus) {
    display: flex;
  }
  //fix
  img {
    margin-right: 1rem;
    border-radius: 50%;
  }
}