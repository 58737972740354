.homepage__hero{
    min-height: calc(100vh - #{$navigation-height});
    padding: 1rem;
    //background-image: url(/images/surgtime-hero-or.jpg);
    background-size: cover;
    background-position: center center;
    position: relative;

    @include mq($from:lg){
        padding: 0 6rem;
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-template-rows: 1fr;
        grid-gap: 4rem;
        min-height: min-content;
        height: calc(100vh - #{$navigation-height});
    }

    @include mq($from:lg-plus){
        grid-template-columns: 1fr 1fr;
    }

    @include mq($from:xl){
        padding: 0 12rem;
    }

    @include mq($from: lg, $until: lg-plus){
        max-height: 35rem;
    }
    .button{
        svg{
            margin-right: .5rem;
            position: relative;
            top: -2px;
        }
    }
}

.hero__message{
    text-align: center;
    padding-bottom: 2rem;
    @include mq($until:lg){
        max-width: 27rem;
        margin: 0 auto;
    }
    @include mq($from:lg){
        text-align: left;
        position: static;
        align-self: center;
        padding-bottom: 4rem;
    }
    h1{
        //text-shadow: 0px 2px 3px rgba(0,0,0,0.3);
        color: $brand;
        font-weight: 200;
        line-height: 1.2;
        margin-bottom: 1rem;
        font-size: 2em;
        margin-top: 0rem;
        @include mq($from:lg){
            font-size: 3em;
            margin-bottom: 2rem;
        }

        @include mq($from:lg-plus){
            font-size: 3.6em;
        }
        
    }
    p{
        @include mq($from:lg){
            max-width: 29rem;
            margin-bottom: 2rem;
        }
        @include mq($from:lg-plus){
            
        }
    }
    .button{
        margin-right: 1em;
        @include mq($from:lg-plus){
            font-size: 1.2em;
            margin-right: 1.5em;
        }
    }
}


.hero__image{
    align-self: center;
    svg{
        max-height: 38rem;
    }
}

.homepage___about{
    background-color: #fff;
    min-height: max-content;
    
    @include mq($from:lg){
        padding-bottom: 4rem;
        display: grid;
        grid-gap: 4rem;
        grid-template-columns: 1fr minmax(auto, 28rem);
        grid-template-rows: 1fr;
    }
    @include mq($from:lg-plus){
        max-height: 100vh;
        grid-template-columns: 1fr minmax(auto, 38rem);
        grid-gap: 4rem;
        padding-left: 2rem;
    }
    @include mq($from:xl){
        grid-gap: 8rem;
        grid-template-columns: 1fr minmax(auto, 42rem);
    }
}

.about__message{
    padding: 0 2rem 0rem;
    @include mq($from:lg){
        grid-column: 2 / span 1; 
        align-self: center;
    }
    @include mq($from:xl){
        padding-right: 8rem;
    }
}
.about__title{
    font-size: 2em;
    line-height: 1.2;
    font-weight: 200;
    margin-bottom: 3rem;
    //max-width: 10em;
    @include mq($from:lg-plus){
        font-size: 3em;
        line-height: 1.2;
        font-weight: 200;
        margin-bottom: 3rem;
    }
}

.about__paragraph{
}

.about__image{
    @include mq($from:lg){
        padding-left: 2rem;
        margin-top: 0;
        align-self: end;

    }
}


.homepage___global-education{
    background-repeat: no-repeat;
    background-position: bottom center;
    padding: 0;
    background-color: #fff;
    min-height: max-content;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    @include mq($from:md-plus){
        
    }
    @include mq($from:lg){
        display: grid;
        grid-gap: 4rem;
        grid-template-columns: minmax(auto, 28rem) 1fr ;
        grid-template-rows: 1fr;
    }
    @include mq($from:lg-plus){
        grid-template-columns: minmax(auto, 34rem) 1fr ;
        grid-gap: 4rem;
    }
    @include mq($from:xl){
        grid-gap: 8rem;
        grid-template-columns: minmax(auto, 42rem) 1fr;
    }
}

.global-education__message{
    padding: 2rem;
    z-index: 100;
    order: 2;
    background-color: #a1bc97;
    position: relative;
    top: -2px;
    color: #fff;
    @include mq($from:lg){
        color: initial;
        position: initial;
        top: 0;
        background-color: transparent;
        order: 1;
        padding: 0 0 0 2rem;
        grid-column: 1 / span 1; 
        grid-row: 1;
        align-self: center;
    }
    @include mq($from:lg-plus){
        padding: 0 0 0 6rem;
    }
}
.global-education__title{
    font-size: 2em;
    line-height: 1.2;
    font-weight: 200;
    margin-bottom: 3rem;
    max-width: 10em;
    @include mq($from:lg-plus){
        font-size: 3em;
        line-height: 1.2;
        font-weight: 200;
        margin-bottom: 3rem;
    }
}

.global-education__paragraph{
}

.global-education__image{
    @include mq($from:lg){
        grid-column: 1 / span 2;
        grid-row: 1;
        margin-top: 0;
        align-self: end;
    }
}


.homepage__get-started{
    height: 80vh;
    min-height: max-content;
    padding: 2rem;
    display:flex;
    flex-direction: column;
    justify-content: center;
    background-color: $brand;
    @include mq($from:lg-plus){
        padding-top: 6rem;
        padding-bottom: 6rem;
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

.get-started__title{
    color: #fff;
    font-size: 2em;
    line-height: 1.2;
    font-weight: 200;
    margin-bottom: 3rem;
    @include mq($from:lg-plus){
        font-size: 3em;
        line-height: 1.2;
        font-weight: 200;
        margin-bottom: 4rem;
    }
}


.get-started__container{
    background-color: #fff;
    border-radius: .5rem;
    margin: 0; padding: 2rem 0;
    list-style-type: none;
    @include mq($from:lg){
        padding-top: 4rem;
        padding-bottom: 3rem;
        grid-column-gap: 2rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-content: center;
    }
}


.get-started__item{
    text-align: center;
    margin-bottom: 2rem;
    @include mq($from:lg){
        margin-bottom: 1rem;
    }
    ul{
        display: none;
        font-size: .8em;
        list-style-type: none;
        padding: 0;
        margin: 0 auto;
        margin-bottom: 2rem;
        li{
            margin-bottom: 1rem;
        }

    }
    img{
        width: 100%;
        max-width: 50%;
        height: auto;
        margin: 0 auto;
        margin-bottom: 2rem;
    }
    h3{

        margin-bottom: 2rem;
    }
    p{
        color: #999;
    }
}

.get-started__action{
    text-align: center;
    font-size: 1.2em;
    @include mq($from:lg){
        font-size: 1.3em;
        grid-column: span 3;
        .button{
            padding: 0.25em 2.2em .35em;
        }
    }
}

.testimonials__title{
    //
}

.testimonials__container{
    list-style-type: none;
    margin: 0; padding: 0;
}

.testimonial{

}


.homepage__watch-surgeries{
    background-color: #4670e8;
}

.watch-surgeries__container{
    display: grid;
    grid-template-columns: 1fr;
    @include mq($from:md){
        grid-template-columns: 1fr 1fr;
    }
}

.watch-surgeries__message{
    color: #fff;
    padding: 2rem;
    padding-top: 0;
    @include mq($from:md){
        padding: 0;
        align-self: center;
        justify-self: center;
    }
    @include mq($from:lg){
        padding-right: 8rem;
    }
}

.watch-surgeries__title{
    font-size: 2em;
    line-height: 1.2;
    font-weight: 200;
    margin-bottom: 3rem;
    max-width: 10em;
    @include mq($from:lg-plus){
        font-size: 3em;
        line-height: 1.2;
        font-weight: 200;
        margin-bottom: 3rem;
        
    }
}


.homepage__testimonials{
    display: none;
    min-height: max-content;
    background-color: #fff;
    padding: 2rem;
    @include mq($from:lg-plus){
        padding-bottom: 6rem;
    }
}


.testimonials__container{
    display: grid;
    align-content: center;
    @include mq($from:md){
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
    }
    @include mq($from:lg-plus){
        padding: 0 4rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 2rem;
    }
}

.testimonials__title{
        font-size: 2em;
        line-height: 1.2;
        font-weight: 200;
        margin-bottom: 3rem;
        max-width: 10em;
        @include mq($from:lg-plus){
            font-size: 3em;
            line-height: 1.2;
            font-weight: 200;
            margin-bottom: 3rem;
            padding-left: 4rem;
            margin-top: 1em;
        }
}
.testimonial{
    &:before{
        content: "“";
        color: $brand;
        font-size: 4em;
        position: relative;
        margin-bottom: -10px;
        font-family: serif;
    }
    p{
        margin-top: -2em;
    }
}

.div-block-6 {
    font-size: 1rem;
    display: grid;
    flex-wrap: nowrap;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;

    img {
        width: 100%;
    }
}

.border-card {
    display: flex;
    overflow: hidden;
    margin-right: 0rem;
    padding: 0rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    border-radius: 11px;
    background-color: #fff;
    box-shadow: 0 8px 13px 0 rgba(0, 0, 0, 0.1);
}

.heading {
    margin-top: 2rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    font-size: 1.5rem;
    line-height: 1.5;
}

.text-block {
    min-height: 4.1rem;
    padding: 0rem 1.5rem 1rem;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 0.875rem;
    line-height: 1.5;
}

.divider-line {
    margin-top: 0rem;
    height: 4px;
    background-color: #0073ec;
}

.divider-line.purple-line {
    background-color: #a644e5;
}

.divider-line.turquoise-line {
    background-color: #00caaa;
}

.audience-container {
    background-color: #f5f5f5;
    padding: 6rem 3rem;
}


.section-4 {
    display: -ms-grid;
    display: grid;
    min-height: 75vh;
    padding-top: 0rem;
    padding-right: 0rem;
    padding-left: 0rem;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 5fr 2fr;
    grid-template-columns: 5fr 2fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}


.image-3 {
    position: static;
    width: 100%;
    height: auto;
    max-width: 5rem;
    margin-right: 2rem;
    float: none;
}


@media (max-width: 767px) {
    .section-4 {
        -ms-grid-columns: 5fr;
        grid-template-columns: 5fr;
    }
}

.div-block-10 {
    max-width: 60rem;
    padding: 4rem;
}

.div-block-11 {
    background-image: url(https://uploads-ssl.webflow.com/5dbcf18…/5dbe463…_earth-nasa.jpeg);
    background-position: 50% 50%;
    background-size: cover;
}

.div-block-11 {
    background-image: url(https://uploads-ssl.webflow.com/5dbcf18…/5dbe463…_earth-nasa.jpeg);
    background-position: 50% 50%;
    background-size: cover;
}

.paragraph {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    line-height: 1.5;

    font-family: Montserrat, sans-serif;
    color: #1a1b1f;
    font-weight: 400;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 2rem;
}

.paragraph-3 {
    margin-bottom: 1rem;
}

.image-4 {
    margin-bottom: 4.1rem;
}

@media (max-width: 991px) {
    .image-4 {
        margin-bottom: 2rem;
    }
}




.div-block-12 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 2rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.button-4 {
    background-color: #0ca3d9;
}
.w-button {
    display: inline-block;
    padding: 9px 15px;
    background-color: #3898EC;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
}

.div-block-11 {
    background-image: url('https://uploads-ssl.webflow.com/5dbcf18515cb7fb07a9cdfed/5dbe46355ad64ffcf8833af4_earth-nasa.jpeg');
    background-position: 50% 50%;
    background-size: cover;
}

@media (max-width: 767px) {

    .div-block-11 {
        display: none;
    }
}

.heading-5 {
    font-family: Montserrat, sans-serif;
    color: #1a1b1f;
    box-sizing: border-box;
    margin: .67em 0;
    margin-top: 20px;
    line-height: 62px;
    max-width: 35rem;
    margin-bottom: 2.5rem;
    font-size: 2rem;
    font-weight: 500;
}



.about-page {

.div-block-15 {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding: 0rem;
  text-align: center;
}

.div-block-15.block15-ourpurpose {
  text-align: left;
}

.heading-7 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #0ca3d9;
  font-size: 1.5rem;
}

.heading-8 {
  margin-top: 0rem;
}

.paragraph-5 {
  margin-top: 2rem;
}

.section-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5rem 4rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.paragraph-6 {
  margin-bottom: 2rem;
}

.paragraph-7 {
  margin-bottom: 2rem;
}

.section-8 {
  padding: 0rem;
}

.image-6 {
  width: 100%;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png");
  background-position: 0px 0px;
  background-size: 125px;
}

.section-9 {
  padding: 4rem;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.8)), to(transparent)), url("https://uploads-ssl.webflow.com/5dbcf18515cb7fb07a9cdfed/5dbdedbe35e153aab318b6fa_operating-room2.97db3107.jpg");
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8), transparent), url("https://uploads-ssl.webflow.com/5dbcf18515cb7fb07a9cdfed/5dbdedbe35e153aab318b6fa_operating-room2.97db3107.jpg");
  background-position: 0px 0px, 0px 0px;
  background-size: auto, cover;
}

.heading-9 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
}

.paragraph-8 {
  margin-bottom: 3rem;
  color: #fff;
}

.div-block-16 {
  display: -ms-grid;
  display: grid;
  grid-column-gap: 4rem;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-auto-columns: 1fr;
  @include mq($from:md-plus){
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

.heading-10 {
  margin-top: 0px;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
}

.div-block-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.paragraph-9 {
  margin-bottom: 0rem;
}

.image-7 {
  width: 24rem;
  padding-left: 3rem;
}

.div-block-18 {
  overflow: visible;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.div-block-19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.max-width-1 {
  max-width: 46em;
  margin-bottom: 2rem;
}

.div-block-20 {
  width: 100%;
  max-width: 33.4rem;
  min-height: 1rem;
  margin-bottom: 2rem;
  padding: 1.5rem 1rem;
  border-radius: 8px;
  background-color: #b4e3f4;
}

.heading-11 {
  margin-top: 0px;
}



.div-block-8 {
    background-color: #fff;
  }
  
  .audiencecolumn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 2rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-radius: 8px;
    background-color: #fff;
    text-align: left;
  }
  
  .heading-3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .heading-4 {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    line-height: 1.5;
    font-weight: 500;
  }
  
  .div-block-9 {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 4rem;
    grid-row-gap: 0rem;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
}