$button-padding: 0.4em 1.8em;

.button {
  display: inline-block;
  font-size: .9em;
  @include mq($from:md){
      font-size: 1em;
  }
}

.button--block{
  display: block;
}
.button--ghost {
  color: #fff;
  border: 2px solid #fff;
  padding: $button-padding;
  border-radius: 4em;
  text-decoration: none;
  background: transparent;
  &:hover{
    color: #fff;
    text-decoration: none;
  }
}


.button--square {
  color: #fff;
  background-color: $brand;
  padding: $button-padding;
  text-decoration: none;
  border: none;
  &:hover{
    background-color: lighten( $brand, 10% );
    color: #fff;
    text-decoration: none;
  }
  &:active{
    background-color: darken( $brand, 10% );
  }
}

.button--rounded {
  color: #fff;
  background-color: $brand;
  padding: $button-padding;
  text-decoration: none;
  border: none;
  border-radius: 10rem;
  border: 2px solid $brand;
  &:hover{
    background-color: lighten( $brand, 10% );
    border-color: lighten( $brand, 10% );
    color: #fff;
    text-decoration: none;
  }
  &:active{
    background-color: darken( $brand, 10% );
  }
}


.button--ghost--dark {
  color: $brand;
  border: 2px solid $brand;
  &:hover{
    background-color: $brand;
    color: #fff;
    text-decoration: none;
  }
}

.btn-outline-mid{
  color:  #ddd;
  border-color: rgba(0, 0, 0, 0.125);
}