// initiate
@import 'base/variables';
@import 'base/mixins';

// libraries
@import 'lib/reboot'; // reset
@import 'lib/mq'; // media queries

@import 'lib/bootstrap';

// start
@import 'base/base'; // global base styles
@import 'base/forms'; // forms & inputs

// components
@import 'components/buttons';
@import 'components/navbar';
@import 'components/dashboard';
@import 'components/wrapper';
@import 'components/header';
@import 'components/hero';
@import 'components/message';
@import 'components/panel';
@import 'components/pane';

@import 'components/login';
@import 'components/register';

@import 'components/footer';

// news
@import 'components/news';

// single surgery styles
@import 'components/single/surgeon';
@import 'components/single/single-hero';
@import 'layouts/single';

// surgeries styles
@import 'layouts/surgeries';
@import 'components/surgeries-card';
@import 'components/registered-users';


// marketing site
@import 'marketing-site/home-page';
@import 'marketing-site/product-page';
@import 'marketing-site/navigation';
@import 'layouts/about';

// admin
@import 'components/admin/edit-surgeries';
@import 'components/admin/users';
@import 'components/admin/surgeries';
@import 'components/admin/groups';
@import 'components/admin/schedule-surgery';

// Temp
.div-block-10 {
  flex: 1;
  padding-right: 40px;
}

h2.global-welcome {
  font-size: 2rem;
  margin: 6rem 0 0 0;
  max-width: 100%;
  text-align: center;
  width: 100%;
}

.partner-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 200px 0;
  background-color: #fafafa;
}

.partner-section section {
  display: block;
  text-align: center;
}

.partner-section-text {
  text-align: right;
  margin-bottom: 50px;
}

.partner-section img {
  max-width: 220px;
  margin: 20px 20px;
  display: inline-block;
}

@media (max-width: 768px) {
  .partner-section {
    flex-direction: column;
    padding: 100px 0;
  }

  .partner-section section {
    width: 100%;
  }

  .partner-section-text {
    margin: 0;
    max-width: 90%;
    text-align: center;
  }

  .partner-section img {
    max-width: 80%;
    margin: 50px auto 0 auto;
    display: block;
  }
}

/* Promo surgeries on home */
.promo-surgeries-container {
  padding: 100px 50px;
  background: #fafafa;
  text-align: center;
}

.promo-surgeries-container button {
  background-color: #3898ec;
  border-radius: 5px;
  border: 1px solid #eee;
  color: #fff;
  margin-top: 40px;
  padding: 10px 40px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.promo-surgeries-container button:hover {
  background-color: #55cbf6;
}

.promo-surgeries-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .promo-surgeries-content {
    flex-direction: column;
  }
}

.promo-surgery-content .title {
  margin-bottom: 40px;
}

.promo-surgery-card {
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #eee;
  flex: 1;
  height: 380px;
  margin: 20px;
  cursor: pointer;
}

.promo-surgery-card:hover {
  box-shadow: #eaeaea 0px 5px 5px;
  transition: ease-in-out 500ms;
}

.promo-surgery-card img {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.promo-surgery-card section {
  padding: 20px;
}

.promo-surgery-card section h4 {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 0;
}

.promo-surgery-card section p {
  margin: 0;
  font-size: 16px;
}

.div-block-10 .paragraph {
  font-size: 1.15rem;
  margin-bottom: 20px;
}

.div-block-11 {
  flex: 1;
}

.learn-more-btn-home {
  background-color: #3898ec;
  border-radius: 5px;
  border: 1px solid #eee;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s ease;
}

.learn-more-btn-home:hover {
  background-color: #55cbf6;
  text-decoration: none;
}

@media (max-width: 767px) {
  .div-block-11 {
    display: none; } }

.heading-5 {
  font-family: Montserrat, sans-serif;
  color: #1a1b1f;
  box-sizing: border-box;
  margin: .67em 0;
  margin-top: 20px;
  line-height: 62px;
  max-width: 35rem;
  margin-bottom: 2.5rem;
  font-size: 2rem;
  font-weight: 500; }

.about-page .div-block-15 {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  padding: 0rem;
  text-align: center; }

.about-page .div-block-15.block15-ourpurpose {
  text-align: left; }

.about-page .heading-7 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #0ca3d9;
  font-size: 1.5rem; }

.about-page .heading-8 {
  margin-top: 0rem; }

.about-page .paragraph-5 {
  margin-top: 2rem; }

.about-page .section-7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5rem 4rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end; }

.about-page .paragraph-6 {
  margin-bottom: 2rem; }

.about-page .paragraph-7 {
  margin-bottom: 2rem; }

.about-page .section-8 {
  padding: 0rem; }

.about-page .image-6 {
  width: 100%;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png");
  background-position: 0px 0px;
  background-size: 125px; }

.about-page .section-9 {
  padding: 4rem;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.8)), to(transparent)), url("https://uploads-ssl.webflow.com/5dbcf18515cb7fb07a9cdfed/5dbdedbe35e153aab318b6fa_operating-room2.97db3107.jpg");
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8), transparent), url("https://uploads-ssl.webflow.com/5dbcf18515cb7fb07a9cdfed/5dbdedbe35e153aab318b6fa_operating-room2.97db3107.jpg");
  background-position: 0px 0px, 0px 0px;
  background-size: auto, cover; }

.about-page .heading-9 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff; }

.about-page .paragraph-8 {
  margin-bottom: 3rem;
  color: #fff; }

.about-page .div-block-16 {
  align-items: center;
  display: -ms-grid;
  display: grid;
  grid-column-gap: 4rem;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-auto-columns: 1fr; }
  @media (min-width: 45em) {
    .about-page .div-block-16 {
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr; } }

.about-page .heading-10 {
  margin-top: 0px;
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1;
  font-weight: 500; }

.about-page .div-block-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.about-page .paragraph-9 {
  margin-bottom: 0rem; }

.about-page .image-7 {
  width: 24rem;
  padding-left: 3rem; }

.about-page .div-block-18 {
  overflow: visible;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.about-page .div-block-19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center; }

.about-page .max-width-1 {
  max-width: 46em;
  margin-bottom: 2rem; }

.about-page .div-block-20 {
  width: 100%;
  max-width: 33.4rem;
  min-height: 1rem;
  margin-bottom: 2rem;
  padding: 1.5rem 1rem;
  border-radius: 8px;
  background-color: #b4e3f4; }

.about-page .heading-11 {
  margin-top: 0px; }

.about-page .div-block-8 {
  background-color: #fff; }

.about-page .audiencecolumn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 2rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 8px;
  background-color: #fff;
  text-align: left; }

.about-page .heading-3 {
  margin-top: 0px;
  margin-bottom: 0px; }

.about-page .heading-4 {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 500; }

.about-page .div-block-9 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 0rem;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto; }

.center-vert {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.product-page .section-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  max-height: 40rem;
  min-height: auto;
  padding: 4rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(32%, transparent), color-stop(87%, rgba(0, 0, 0, 0.63))), url("https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9ab84b38b7dc10657a0c_product-bg.jpg");
  background-image: linear-gradient(180deg, transparent 32%, rgba(0, 0, 0, 0.63) 87%), url("https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9ab84b38b7dc10657a0c_product-bg.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover; }

.product-page .heading-6 {
  max-width: 18em;
  margin-bottom: 1.5rem;
  color: #fff;
  font-size: 3rem; }

.product-page .paragraph-4 {
  max-width: 37rem;
  color: #fff; }

.product-page .div-block-15 {
  display: -ms-grid;
  display: grid;
  padding: 0rem;
  grid-auto-columns: 1fr;
  grid-column-gap: 3rem;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto; }

.product-page .div-block-15.block-15-2 {
  grid-column-gap: 3rem; }

.product-page .heading-7 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #0ca3d9;
  font-size: 1.5rem; }

.product-page .heading-8 {
  margin-top: 0rem;
  font-size: 2rem;
  font-weight: 500; }

.product-page .paragraph-5 {
  margin-top: 2rem; }

.product-page .section-7 {
  display: block;
  padding: 4rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end; }

.product-page .section-7.section-7-2 {
  background-color: #efefef; }

.product-page .paragraph-6 {
  max-width: 35em;
  margin-bottom: 2rem; }

.product-page .paragraph-7 {
  margin-bottom: 2rem; }

.product-page .section-8 {
  padding: 0rem; }

.product-page .image-6 {
  width: 100%;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png");
  background-position: 0px 0px;
  background-size: 125px; }

.product-page .section-9 {
  padding: 4rem;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.8)), to(transparent)), url("https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9a07ab100873c20a941c_operating-room2.97db3107.jpg");
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8), transparent), url("https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9a07ab100873c20a941c_operating-room2.97db3107.jpg");
  background-position: 0px 0px, 0px 0px;
  background-size: auto, cover; }

.product-page .heading-9 {
  margin-top: 0px;
  margin-bottom: 2rem;
  color: #fff;
  text-align: center; }

.product-page .paragraph-8 {
  margin-bottom: 3rem;
  color: #fff;
  text-align: center; }

.product-page .div-block-16 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto; }

.product-page .heading-10 {
  font-size: 1rem; }

.product-page .div-block-17 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.product-page .paragraph-9 {
  margin-bottom: 0rem; }

.product-page .image-7 {
  width: 24rem;
  padding-left: 3rem; }

.product-page .div-block-18 {
  overflow: visible;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.product-page .div-block-19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center; }

.product-page .div-block-20 {
  position: static; }

.product-page .product-join {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 50vh;
  padding: 4rem;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#000), to(rgba(0, 0, 0, 0.45))), url("https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9f6672abe378773f1c87_product-join-cta.jpg");
  background-image: linear-gradient(180deg, #000, rgba(0, 0, 0, 0.45)), url("https://uploads-ssl.webflow.com/5ddc9a07ab10087d600a93e5/5ddc9f6672abe378773f1c87_product-join-cta.jpg");
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover; }

.product-page .div-block-21 {
  text-align: center; }

.site-navigation--marketing {
  margin-top: 1rem;
  border-bottom: none; }
  .site-navigation--marketing .button {
    margin-right: 2rem; }

.about__intro {
  color: #fff;
  padding: 1rem;
  position: relative; }
  .about__intro:before {
    position: absolute;
    background-color: #0ca3d9;
    /* background-image: url("/images/doctor-live-operation.jpg"); */
    background-size: cover;
    top: 0;
    right: 0;
    left: 0;
    bottom: 35%;
    content: "";
    z-index: -1; }
  .about__intro .dots {
    position: absolute;
    bottom: 44%;
    left: -1rem;
    width: 4rem;
    height: 4rem; }
    @media (min-width: 75em) {
      .about__intro .dots {
        left: 6vw;
        width: 10rem;
        height: 10rem; } }
  .about__intro .arrow {
    position: absolute;
    bottom: 60%;
    right: -1rem;
    width: 4rem;
    height: 4rem; }
    @media (min-width: 75em) {
      .about__intro .arrow {
        right: 1vw;
        width: 10rem;
        height: 10rem; } }

.dots {
  width: 10rem;
  height: 10rem;
  opacity: .4; }

.arrow {
  width: 10rem;
  height: auto; }

@media (min-width: 45em) {
  .about__intro__wrapper {
    padding: 0 2rem;
    margin: 0 auto; } }

@media (min-width: 75em) {
  .about__intro__wrapper {
    padding: 0;
    width: 75vw; } }

.about__intro__title {
  margin-top: 0; }
  @media (min-width: 45em) {
    .about__intro__title {
      margin-top: 1em; } }
  @media (min-width: 75em) {
    .about__intro__title {
      margin: 1em 0 2rem;
      font-size: 5em; } }

@media (min-width: 45em) {
  .about__intro__paragraph {
    margin-bottom: 3em; } }

@media (min-width: 75em) {
  .about__intro__paragraph {
    margin-bottom: 6em; } }

.about__intro__grid {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 2rem; }
  @media (min-width: 45em) {
    .about__intro__grid {
      padding: 0 2rem; } }
  @media (min-width: 75em) {
    .about__intro__grid {
      padding: 0;
      max-width: 75vw; } }
  .about__intro__grid img {
    width: 100%;
    height: auto; }

.about__mission {
  padding: 1rem; }
  @media (min-width: 45em) {
    .about__mission {
      padding: 0;
      height: 50vh;
      min-height: max-content;
      display: flex;
      align-content: center;
      flex-direction: column;
      justify-content: center; } }

.mission__title {
  color: #f9ab00; }
  @media (min-width: 45em) {
    .mission__title {
      text-align: center;
      margin-bottom: 2em; } }

@media (min-width: 45em) {
  .mission__paragraph {
    margin: 0 auto;
    max-width: 40rem;
    text-align: center; } }

@media (min-width: 75em) {
  .mission__paragraph {
    font-size: 1.4em;
    max-width: 75vw; } }

@media (min-width: 100em) {
  .mission__paragraph {
    font-size: 1.6em; } }

.about__who-we-are {
  padding: 1rem;
  max-width: 40rem;
  position: relative; }
  @media (min-width: 60em) {
    .about__who-we-are {
      padding: 0;
      max-width: 70vw;
      margin-left: auto;
      padding-right: 12.5vw;
      height: 40vh;
      min-height: max-content;
      display: flex;
      align-content: center;
      flex-direction: column;
      justify-content: center; } }
  .about__who-we-are .dots {
    display: none; }
    @media (min-width: 60em) {
      .about__who-we-are .dots {
        display: block;
        position: absolute;
        left: -18vw;
        opacity: .2;
        height: 10vw;
        width: 10vw; } }

@media (min-width: 60em) {
  .who-we-are__title {
    margin-bottom: 1em; } }

.about__our-goal {
  padding: 1rem; }
  @media (min-width: 60em) {
    .about__our-goal {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 8rem; } }

.our-goal__message {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media (min-width: 75em) {
    .our-goal__message {
      padding-right: 12.5vw; } }

.our-goal__title {
  margin-bottom: 1em; }

.edit__surgery {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem; }
  @media (min-width: 40em) {
    .edit__surgery {
      grid-template-columns: 2fr 1fr; } }
  @media (min-width: 60em) {
    .edit__surgery {
      grid-template-columns: 1fr; } }
  @media (min-width: 75em) {
    .edit__surgery {
      grid-template-columns: 2fr 1fr; } }

.edit__alpha > *:not(:last-child) {
  margin-bottom: 1rem; }

.edit__beta > *:not(:last-child) {
  margin-bottom: 2rem; }

.edit__img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 100%;
  position: relative; }
  .edit__img .edit__img-uploader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    display: flex; }
  .edit__img .input--file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }
  .edit__img .input--file + label {
    font-size: 1em;
    font-weight: 200;
    padding: 0.4em 1.8em;
    color: white;
    background-color: #0ca3d9;
    display: inline-block;
    cursor: pointer; }
    .edit__img .input--file + label svg {
      position: relative;
      top: -2px; }
  .edit__img .input--file:focus + label,
  .edit__img .input--file + label:hover {
    background-color: #55cbf6; }
  .edit__img .input--file:focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px; }

.edit__surgery input {
  width: 100%;
  padding: .5em 1em;
  border: 1px solid #eee; }

.edit__description-textarea {
  width: 100%;
  min-height: 24em;
  padding: 1em;
  border: 1px solid #eee; }

.edit__url .edit__zoom-url {
  margin-bottom: 1rem; }

@media (min-width: 100em) {
  .edit__url {
    display: flex;
    width: 100%; }
    .edit__url .edit__zoom-url {
      margin-bottom: 0; }
    .edit__url > * {
      width: 50%; }
      .edit__url > *:first-child {
        margin-right: 1rem; } }

.edit-surgery__form {
  display: flex; }
  .edit-surgery__form label:not(:last-child) {
    padding-right: 2rem; }
  .edit-surgery__form [for="form--duration"] span {
    display: block; }

.users {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.user {
  display: grid;
  grid-template-columns: 3rem minmax(min-content, 1fr) min-content;
  grid-column-gap: 1rem;
  margin-bottom: 1rem; }
  @media (max-width: 29.99em) {
    .user {
      font-size: .8em;
      grid-column-gap: .5rem; } }
  @media (min-width: 75em) {
    .user {
      grid-template-columns: 4rem 1fr 1fr 10rem;
      align-items: center;
      grid-column-gap: 1rem;
      margin-bottom: 1rem; } }

.user__name:not(.group__user .user__name) {
  font-weight: bolder;
  text-transform: capitalize; }

@media (max-width: 74.99em) {
  .user__img {
    grid-row: 1 / span 2; } }

@media (min-width: 75em) {
  .user__img {
    margin-right: 1rem; } }

@media (max-width: 74.99em) {
  .user__email {
    grid-column: 2; } }

.users__table-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem; }
  @media (max-width: 74.99em) {
    .users__table-header {
      display: none; } }

@media (max-width: 29.99em) {
  .user__groups {
    grid-row: 3;
    margin-top: 1rem; } }

.user__groups select {
  width: 100%; }
  @media (max-width: 74.99em) {
    .user__groups select {
      min-width: 10rem; } }

.surgeries__posts {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.surgeries__post {
  display: flex;
  align-items: baseline; }
  .surgeries__post:not(:last-child) {
    margin-bottom: 1.5rem; }
  .surgeries__post .surgeries__post__title {
    margin: 0;
    flex: 1 1 auto; }
  .surgeries__post h3 {
    font-size: 1rem; }
    @media (min-width: 75em) {
      .surgeries__post h3 {
        font-size: 1.6rem; } }
  .surgeries__post *:not(:last-child) {
    margin-right: 2rem; }

.groups {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.group {
  margin-bottom: 2rem; }

.group__users {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin-top: 1rem;
  flex-flow: row wrap; }

.group__user {
  display: flex;
  align-items: center;
  border: 1px solid #eee;
  padding: .5rem;
  border-radius: 10em;
  margin-right: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
  position: relative; }
  .group__user > * {
    margin-right: .5rem; }
  .group__user .close {
    position: relative;
    top: -3px;
    left: 3px; }

.group__header a {
  margin-left: 2rem; }

.schedule-surgery__form {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-flow: dense;
  grid-gap: .5rem; }
  @media screen and (min-height: 700px) {
    .schedule-surgery__form {
      grid-gap: 2rem; } }
  .schedule-surgery__form label span {
    margin-bottom: .5rem;
    display: block; }
  .schedule-surgery__form textarea {
    min-height: initial;
    height: calc(100% - 2rem); }
  .schedule-surgery__form label[for=form--topic] {
    grid-column: 1 / span 4; }
    .schedule-surgery__form label[for=form--topic] input {
      display: block;
      width: 100%; }
  .schedule-surgery__form label[for=form--description] {
    grid-column: span 4;
    grid-row: span 3; }
  .schedule-surgery__form label[for=form--duration] {
    grid-column: span 2; }
    .schedule-surgery__form label[for=form--duration] select {
      margin-right: .5rem; }
    .schedule-surgery__form label[for=form--duration] select:not(:first-of-type) {
      margin: 0 .5rem; }
    .schedule-surgery__form label[for=form--duration] span {
      display: block; }
  .schedule-surgery__form label[for=form--time] {
    grid-column: span 2; }
    .schedule-surgery__form label[for=form--time] span {
      display: block; }
    .schedule-surgery__form label[for=form--time] #form--time {
      display: block;
      width: 100%; }
  .schedule-surgery__form label[for=form--date] {
    grid-column: span 2; }
  .schedule-surgery__form label[for=hostVideo] {
    grid-column: span 2; }
    .schedule-surgery__form label[for=hostVideo] input {
      margin-right: .5rem; }
    .schedule-surgery__form label[for=hostVideo] input:not(:first-of-type) {
      margin: 0 .5rem; }
    .schedule-surgery__form label[for=hostVideo] span {
      margin-right: 1em; }
  .schedule-surgery__form label[name=participantVideo] {
    grid-column: span 2; }
    .schedule-surgery__form label[name=participantVideo] input {
      margin-right: .5rem; }
    .schedule-surgery__form label[name=participantVideo] input:not(:first-of-type) {
      margin: 0 .5rem; }
    .schedule-surgery__form label[name=participantVideo] span {
      margin-right: 1em; }
  .schedule-surgery__form label[for=record] {
    grid-column: span 2; }
    .schedule-surgery__form label[for=record] span {
      margin-right: 1em;
      display: inline; }
  .schedule-surgery__form #form--date {
    width: 100%; }

.schedule-surgery__form__button {
  grid-column: span 2; }

.MuiTableContainer-root {
  box-shadow: none !important;
}

.MuiTableCell-root {
  border-bottom: 1px solid rgb(243, 243, 243) !important;
}

.MuiTableCell-head {
    color: #353d57 !important;
    font-weight: 600 !important;
    line-height: 1.5rem !important;
    font-size: 16px !important;
}

.MuiTableCell-body {
  color: #353d57 !important;
  font-size: 15px !important;
}

.MuiTableCell-sizeSmall {
  padding: 6px 6px 6px 12px !important;
}

/* Helpers */
.cursor-pointer {
  cursor: pointer !important;
}

.hover_color-blue:hover {
  color: #0ca3d9 !important;
}

.hover_color-red:hover {
  color: #f35627 !important;
}

@keyframes pulse {
  0% { transform: scale(1); }
  30% { transform: scale(1); }
  40% { transform: scale(1.1); }
  50% { transform: scale(1); }
  60% { transform: scale(1); }
  70% { transform: scale(1.2); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}

.pulse {
animation: pulse 1s ease-in-out infinite;
}

.site-navigation__mobile-menu {
  display: none;
}

/* Responsive */
@media (max-width: 20em) {
    /* "sm <= 320px (20em)" */
}
@media (max-width: 960px) {
    .div-block-6 {
      grid-template-columns: 1fr;
    }

    .div-block-10 {
      padding: 2rem;
    }

    .div-block-12 {
      flex-direction: column;
      align-items: center;
    }

    .div-block-12 img {
      margin-right: 0;
      margin-bottom: 20px;
      max-width: 7rem;
    }

    .div-block-12 p {
      text-align: center;
    }

    .heading-5 {
      font-size: 1.75rem;
    }

    .paragraph {
      font-size: 1.2rem;
    }

    .paragraph-3 {
      text-align: center;
    }

    .button-4 {
      color: rgb(255, 255, 255);
      display: block;
      margin: 0 auto;
      text-align: center;
      width: 70%;
    }

    .audience-container {
      padding: 5rem 2rem;
    }

    .section-4 {
      padding: 0;
      text-align: center;
    }

    .about-page .ourpurpose {
      padding: 2rem;
    }

    .about-page .join-us {
      padding: 4rem 1rem;
    }

    .about-page .join-us .w-button {
      text-align: center;
      width: 100%;
    }

    .site-footer__container {
      justify-content: center;
    }

    .site-footer__column-1 {
      text-align: center;
    }

    .site-footer__column-1 img {
      display: inline-block;
    }

    .login.wrapper {
      padding: 2rem 2rem;
    }

    .site-navigation__mobile-menu {
      display: inline-block;
    }

    .sign-up__meta {
      flex-direction: column;
    }

    .sign-up__meta .button {
      font-size: 20px;
      margin-bottom: 40px;
      padding: 15px;
      width: 100%;
    }

    .site-navigation__nav__item .nav-anchor {
      padding: 0;
      margin-bottom: 50px;
    }

    .MuiTableCell-head {
      white-space: nowrap;
    }

    .MuiTableCell-body {
      white-space: nowrap;
    }

    .upcoming-surgeries__list.surgeries__grid__list {
      display: block;
    }

    .surgeries__grid__item {
      margin-bottom: 30px;
    }
}
@media (max-width: 640px) {


    .product-page .section-6, .product-page .section-7 {
      padding: 4rem 2rem;
    }

    .product-page .div-block-15 {
      display: flex;
      flex-direction: column-reverse;
      text-align: center;
    }

    .product-page .div-block-15 h1 {
      font-size: 1.5rem;
    }

    .product-page .div-block-15.block-15-2 {
      flex-direction: column;
    }

    .product-page .div-block-15 img {
      width: 100%;
      margin-bottom: 30px;
    }
}
@media (max-width: 45em) {
    /* "md-plus <= 720px (45em)" */
}
@media (max-width: 60em) {
    /* "lg <= 960px (60em)" */
}
@media (max-width: 75em) {
    /* "lg-plus <= 1200px (75em)" */
}
@media (max-width: 100em) {
    /* "xl <= 1600px (100em)" */
}

.sticky-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}


/* Reactour customisation */
.reactour__helper {
  font-size: 17px;
  max-width: 450px;
  padding-right: 90px;
  border-radius: 20px;
}

.reactour__helper > span {
  height: 35px;
  line-height: 35px;
  padding: 0;
  width: 35px;
}

/* React easy crop customisation */
.MuiSlider-root .MuiSlider-rail, .MuiSlider-root .MuiSlider-track {
  border-radius: 10px;
  height: 13px;
}

.MuiSlider-root .MuiSlider-thumb {
  height: 25px;
  left: 0%;
  margin-top: -6px;
  width: 25px;
}

/* Signup Flow */
.SignupFlow-TextField .MuiOutlinedInput-root:focus {
  border: 1px solid orange !important;
  outline: none !important;
}

.SignupFlow-TextField.--credential {
  width: 360px;
}

.SignupFlow-TextField.--credential input {
  text-align: center;
}

.MuiStep-horizontal {
  padding: 0 15px;
}

.MuiStepLabel-label.MuiStepLabel-active {
  color: #585858;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #0da3d9 !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #0da3d9 !important;
}

.MuiStepIcon-text {
  font-size: 13px !important;
}

@media (max-width: 45em) {
  /* "md-plus <= 720px (45em)" */

  .SignupFlow .MuiOutlinedInput-root {
    display: block;
    margin: 10px auto !important;
    width: 80% !important;
  }

  .SignupFlow .MuiFormControl-root {
    margin: 0 auto !important;
    width: 100% !important;
  }

  .SignupFlow .MuiStepper-horizontal {
    display: none; /* or maybe outside the container */
  }

  .MuiOutlinedInput-input:-webkit-autofill {
    width: 95%;
  }

  .SignupFlow h2 {
    font-size: 22px !important;
    margin: 0 auto 10px auto !important;
    width: 75% !important;
  }

  .SignupFlow h5 {
    margin: 0 auto !important;
    width: 75% !important;
  }
}

.UserSidebarMenu {
  border-right: 1px solid #eee;
}