.broadcast-page-public {
  text-align: center;
  font-size: 22px;
  margin-top: 100px;
}

.broadcast-page-wrapper {
  height: 100%;
  position: relative;
  margin: -30px -45px;
  padding: 0 45px;
  background: #1d5485;

  .setup-pane {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-top: -30px;

    .setup-closer {
      align-items: center;
      border-radius: 10px;
      color: #ececec;
      cursor: pointer;
      display: flex;
      font-size: 13px;
      font-weight: 200;
      justify-content: center;
      width: 150px;
      height: 35px;
      margin-top: 20px;
      background: transparent;
      border: 1px solid orange;
      color: orange;

      &:hover {
        background: orange;
        color: #fff;
      }
    }

    .selectors-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;

      .video-input-selector {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        margin: 15px;

        .video-input-selector-preview {
          background: #e4e4e4;
          border-radius: 6px;
          height: 320px;
          width: 420px;
          position: relative;

          video {
            border-radius: 6px;
            height: 100%;
            width: 100%;

            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
          }
        }

        select {
          border: 1px solid #eee;
          margin-top: 10px;
          padding: 15px;
          width: 100%;
          border-radius: 10px;
        }

        .instruction-text {
          color: #ccc;
        }
      }
    }

    .start-btn {
      color: #fff;
      background: #009617;
      box-shadow: none;
      margin-top: 40px;
      width: 220px;
      height: 50px;
      border-radius: 10px;
      font-weight: 600;

      &:disabled {
        background: #a0a0a0;
        cursor: not-allowed;
      }

      &:hover {
        background: #10c12b;
      }
    }
  }

  .broadcast-pane {
    height: 100%;
    margin-top: -25px;

    .MuiGrid-container {
      height: 100%;
    }

    & > .MuiGrid-container {
      align-content: center;
      align-items: center;
      justify-content: center;
    }

    .broadcast-pane-top {
      height: 60%;
      max-height: 60%;
      width: 100%;

      .MuiGrid-item {
        height: 100%;

        &:first-of-type {
          position: relative;
        }
      }

      .broadcast-pane-video {
        width: 100%;
        height: 100%;
        background: #cecece;
        border: 3px solid #43e1d0;
        border-radius: 10px;

        video {
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }

        &.broadcast-pane-broadcaster-big {
          width: 98%;
        }

        &.broadcast-pane-broadcaster-small {
          height: 60%;
        }
      }

      .canvas-container {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 98% !important;
        height: 100% !important;

        canvas {
          width: 100% !important;
          height: 100% !important;
        }
      }

      .broadcast-pane-broadcaster-small-info {
        height: 20%;
        display: flex;
        align-items: center;
        margin-top: 30px;

        img {
          width: 160px;
          margin-right: 30px;
        }

        .broadcast-pane-broadcaster-small-info-details {
          p {
            color: #d2d2d2;
            font-size: 20px;
            margin-bottom: 0;

            &:last-of-type {
              font-size: 14px;
            }
          }
        }
      }

      .broadcast-details {
        background: #039de1;
        border-radius: 10px;
        color: #fff;
        height: 100%;
        margin-top: 10px;
        padding: 20px;

        h4 {
          font-size: 19px;
          font-weight: 500;
        }

        p {
          margin: 5px 0 0 0;
          font-size: 14px;
          color: #e2e2e2;
          width: 90%;

          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    .broadcast-pane-bottom {
      height: 170px; // height of video thumbnail + 10px
      width: 100%;
      margin-top: 5px;

      .MuiGrid-item {
        height: 100%;
      }

      .broadcast-pane-viewers {
        display: block;
        height: 100%;
        overflow-x: auto;
        white-space: nowrap;
      }
    }

    .controls {
      position: absolute;
      bottom: 40px;
      right: 120px;
      z-index: 999;

      button {
        width: 180px;
        border-radius: 50px;
        color: #fff;
        height: 40px;
        font-weight: 400;
        font-size: 12px;

        &:first-of-type {
          margin-right: 10px;
        }

        &:focus,
        &:active {
          border: none;
          outline: none;
        }

        &.end-btn {
          background: #ff964b;
          color: #fff;
        }

        &.deactivate {
          background: #009688;
        }

        &.activate {
          background: #962a00;
        }
      }
    }

    .broadcast-pane-broadcaster-big-video-wrapper {
      & > div {
        border-radius: 10px;
      }
    }

    .broadcast-pane-broadcaster-small-video-wrapper {
      position: relative;

      & > div {
        border-radius: 10px;
      }

      .broadcaster-name {
        background: rgba(0, 0, 0, 0.6);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        bottom: 0;
        color: #fff;
        font-size: 13px;
        padding: 5px 0;
        position: absolute;
        text-align: center;
        width: 100%;
        z-index: 9;
      }

      .viewer-mute-icon {
        align-items: center;
        background: #ff000080;
        border-radius: 60px;
        display: inline-flex;
        height: 50px;
        justify-content: center;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 50px;

        svg {
          font-size: 20px;
        }
      }
    }

    .broadcast-pane-broadcaster-viewer-video-wrapper {
      display: inline-block;
      margin-right: 10px;
      position: relative;
      background: #404040;
      border-radius: 12px;

      & > div {
        border-radius: 10px;
      }

      .viewer-name {
        background: rgba(0, 0, 0, 0.6);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        bottom: -17px;
        color: #fff;
        font-size: 12px;
        padding: 3px 0;
        position: absolute;
        text-align: center;
        width: 100%;
        z-index: 9;
        border-radius: 0 0 10px 10px;
      }

      .viewer-mute-icon {
        align-items: center;
        background: #ff000080;
        border-radius: 60px;
        display: inline-flex;
        height: 30px;
        justify-content: center;
        position: absolute;
        right: 5px;
        top: 5px;
        width: 30px;
      }
    }

    .broadcast-pane-broadcaster-annotation-controls {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;
      margin-top: 5px;

      .MuiButton-root {
        height: 30px;
        width: 40px;
        padding: 0;
        min-width: 0;
        margin-left: 4px;
        background: #e0e0e0;

        &.drawing-btn-active {
          background: #ff0000;
          color: #fff;
        }

        svg {
          margin: 0 !important;
        }
      }
    }
  }
}

.end-broadcast-confirmation-modal {
  margin: 20% auto;
  width: 500px;

  .MuiPaper-root {
    padding: 30px;
    text-align: center;

    p {
      font-size: 22px;
    }

    button {
      margin-top: 50px;
      margin-bottom: 10px;
      color: #fff;
      background: orange;
      font-weight: 600;
      width: 240px;
      height: 40px;

      span {
        color: #fff;
        text-decoration: none;
      }
    }

    span {
      cursor: pointer;
      text-decoration: underline;
      color: #7b7474;

      &:hover {
        color: #000;
      }
    }
  }
}
