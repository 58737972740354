// media query viewer 

body:before{
    bottom:0;
    top:inherit;
    left: 0;
    right: inherit;
}

img{
    max-width: 100%;
    height: auto;
    display: block;
}

.display\:flex{
    display: flex;
}

.flex\:align-center{
    align-items: center
}


.bold{
    font-weight: bold;
}

.space-between{
    justify-content: space-between;
}

.spacer-bottom{
    margin-bottom: 2rem;
}


a:focus{
    outline: 1px dotted #c0c0c0;
}