.registered-users{
    display: flex;
    align-items: center;
}

.registered-users__img{
    border-radius: 50%;
    margin-left: -1rem;
    box-shadow: -2px 1px 2px rgba(0,0,0,0.2);
}

.registered-users__registered{
    margin-left: .5rem;
}

.registered-surgeries--empty{
    background-color: #efefef;
    .registered-surgeries--empty__container{
        text-align: center;
        .button{
            margin-top: 2rem;
        }
    }
}